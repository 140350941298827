/* Handle */
.sp-tabs-scrollable-container::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scroll handle */
    border-radius: 4px; /* Roundness of the scroll handle */
}

/* Handle on hover */
.sp-tabs-scrollable-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scroll handle when hovered */
}

/* Track */
.sp-tabs-scrollable-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scroll track */
    border-radius: 4px; /* Roundness of the scroll track */
}